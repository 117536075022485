import "../css/index.scss"

var debug = true
const mobileBreakpoint = 782
let mobileView = false
let menuOpen = false
let menuChanging = false
let menuChangingTimeout

let adminBarHeight = 0
let siteHeaderHeight = 0
let lastScroll = 0
let prevScrollDelta = 0
let supportsPassive = false // for scroll event listeners

let orientationLandscape = true

jQuery(function ($) {
  if (history.scrollRestoration) {
    history.scrollRestoration = "manual"
  }
  $("html").addClass("js") // js is working

  // for ios touch -> enable active state for links
  document.addEventListener("touchstart", function () {}, false)

  // -----------------------------------------------
  const siteContainer = $(".site-container")
  const siteHeader = $(".site-header")
  const navContainer = $(".site-menu")

  const siteTitleLink = $(".site-title")
  const menuMain = $(".menu-main")
  const menuNavLinks = $(".menu-main > li.menu-item > a")
  const openCloseMenu = $(".nav-icon")

  // -----------------------------------------------------------------------------------------------------

  setLandscape()
  touchSupport()

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  headerHeight()
  checkMobileView()

  // hide and fade in nav and content elements with visibility hidden
  siteContainer.hide()
  siteContainer.css("visibility", "visible")
  siteContainer.fadeIn()
  navContainer.fadeIn()

  setTimeout(() => {
    // safety
    headerHeight()
  }, 100)

  // open close mobile menu
  openCloseMenu.on("click", function () {
    if (!openCloseMenu.hasClass("checked")) {
      openMenu()
    } else {
      closeMenu()
    }
  })

  function openMenu() {
    menuChanging = true
    // console.log("open the menu", menuChanging)
    openCloseMenu.addClass("checked")
    menuMain.stop().slideDown(200, () => {
      clearTimeout(menuChangingTimeout)
      setTimeout(() => {
        menuOpen = true
        // console.log("slide down finsih")
        menuChanging = false
      }, 200)
    })
  }

  function closeMenu() {
    if (menuOpen) {
      // console.log("close the menu")
      openCloseMenu.removeClass("checked")
      menuMain.stop().slideUp(200, () => {
        menuChanging = false
        menuOpen = false
      })
    }
  }

  // menu and title navigation ------------------------------------------------------------------------------------------------
  $("a")
    .add(siteTitleLink)
    .on("click", function (e) {
      const ref = $(this).attr("href")
      if (ref.startsWith("#")) {
        e.preventDefault()

        closeMenu()

        const $target = $(`${ref}`)

        if ($target.length) {
          let top = 0

          if (ref == "#paradiesli") {
            top = $target.offset().top - adminBarHeight - siteHeaderHeight - 100
          } else {
            const targetMarginTop = $target.css("margin-top").replace("px", "")
            // console.log(targetMarginTop)
            // top = $target.offset().top - adminBarHeight - siteHeaderHeight - targetMarginTop - (window.innerHeight - adminBarHeight) / 2
            top = $target.offset().top - adminBarHeight - siteHeaderHeight - (window.innerHeight - adminBarHeight - siteHeaderHeight) / 2 + $target.height() / 2
          }

          $("html, body").animate(
            {
              scrollTop: top
            },
            400
          )
        }
      }
    })

  // --------------------------------------------------------------------------------------------------------------------------------------------------------

  function touchSupport() {
    const touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
      closeMenu()
    } else {
      // desktop
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")
      // closeMenu()
      menuMain.css("display", "")
      menuMain.css("padding", "")
      menuMain.css("margin", "")
      menuMain.css("height", "")
    }
    // console.log("window width", $(window).width());
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function headerHeight() {
    siteHeaderHeight = siteHeader.outerHeight()
    $(":root").css("--siteHeaderHeight", `${siteHeaderHeight}px`)

    // add banner height
    let bannerHeight = 0
    const bannerBlock = $(".mz-banner-block--show")
    if (bannerBlock.length) bannerHeight = bannerBlock.outerHeight()
    $(":root").css("--bannerHeight", `${bannerHeight}px`)
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = document.documentElement.clientHeight * 0.01
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }

  function checkSupportsPassive() {
    // Test via a getter in the options object to see if the passive property is accessed
    try {
      var opts = Object.defineProperty({}, "passive", {
        get: function () {
          supportsPassive = true
        }
      })
      window.addEventListener("testPassive", null, opts)
      window.removeEventListener("testPassive", null, opts)
    } catch (e) {}
  }

  let resizeTimeout = null
  let resizeTimeout2 = null

  window.addEventListener("resize", () => {
    checkMobileView()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      calcVhPropertyFromClientHeight()
      headerHeight()
      checkMobileView()
      headerHeight()
    }, 500)

    clearTimeout(resizeTimeout2)
    resizeTimeout2 = setTimeout(() => {
      // for ios safari to get correct window height
      calcVhPropertyFromClientHeight()
    }, 1000)
  })

  document.addEventListener(
    "scroll",
    function (e) {
      // const currentScroll = window.pageYOffset
      const currentScroll = window.scrollY
      const scrollDelta = 0.5 * (prevScrollDelta + (currentScroll - lastScroll))
      // console.log("scroll: ", currentScroll, lastScroll, scrollDelta, prevScrollDelta)

      prevScrollDelta = scrollDelta

      if (mobileView) {
        // if (window.scrollY === 0) {
        //   // on top
        // } else

        if (!menuChanging && currentScroll > lastScroll && scrollDelta > 5) {
          // DOWN ---
          // console.log("down down down", menuChanging)
          closeMenu()
        }
      }

      lastScroll = Math.max(0, currentScroll)
    },
    supportsPassive ? { passive: true } : false
  )
})
